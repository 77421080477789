import React from 'react';

const FlashingTooltipsGuide = () => {
    // Styles for the first tooltip
    const tooltipStyles1 = {
        position: 'absolute',
        top: '-60px', // Position above the element
        left: '50%',
        padding: '12px 20px',
        borderRadius: '8px',
        fontSize: '14px',
        color: '#fff',
        whiteSpace: 'nowrap',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        background: 'linear-gradient(135deg, #ff6f61, #d45079)',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
        animation: 'flash 1.5s infinite', // Flashing animation
        animationTimingFunction: 'ease-in-out',
        transform: 'translate(-50%, -100%)', // Center above the target
    };

    // Arrow styles for the first tooltip
    const arrowStyles1 = {
        position: 'absolute',
        top: '100%', // Position the arrow below the tooltip
        left: '50%',
        transform: 'translateX(-50%)',
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid #ff6f61',
        filter: 'drop-shadow(0 0 8px rgba(255, 111, 97, 0.8))', // Glow effect
    };

    // Styles for the second tooltip
    const tooltipStyles2 = {
        position: 'absolute',
        top: '-60px', // Position above the element
        left: '50%',
        padding: '12px 20px',
        borderRadius: '8px',
        fontSize: '14px',
        color: '#fff',
        whiteSpace: 'nowrap',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        background: 'linear-gradient(135deg, #82e034, #6bb024)',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
        animation: 'flash 1.5s infinite', // Flashing animation
        animationTimingFunction: 'ease-in-out',
        transform: 'translate(-50%, -100%)', // Center above the target
    };

    // Arrow styles for the second tooltip
    const arrowStyles2 = {
        position: 'absolute',
        top: '100%', // Position the arrow below the tooltip
        left: '50%',
        transform: 'translateX(-50%)',
        width: '0',
        height: '0',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid #82e034',
        filter: 'drop-shadow(0 0 8px rgba(130, 224, 52, 0.8))', // Glow effect
    };

    const highlightStyles = {
        position: 'absolute',
        transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
        opacity: 1,
    };

    return (
        <div style={{ position: 'relative', padding: '20px', minHeight: '300px' }}>
            {/* Step 1: Highlight Chat Box */}
            <div style={{ ...highlightStyles, top: '50px', left: '150px' }}>
                <div style={tooltipStyles1}>
                    也可以按連絡人直接視訊通話
                    <div style={arrowStyles1}></div>
                </div>
                <div style={{ padding: '10px', backgroundColor: '#e0e7ff', borderRadius: '5px' }}>Chat Box</div>
            </div>

            {/* Step 2: Highlight Send Button */}
            <div style={{ ...highlightStyles, top: '250px', left: '150px' }}>
                <div style={tooltipStyles2}>
                    可按麥克風說話
                    <div style={arrowStyles2}></div>
                </div>
                <button style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007bff', color: '#fff', border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                    Send
                </button>
            </div>
        </div>
    );
};

// Inject the keyframes for the flashing animation
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(`
    @keyframes flash {
        0%, 100% { opacity: 1; transform: scale(1); }
        50% { opacity: 0.8; transform: scale(1.05); }
    }
`, styleSheet.cssRules.length);

export default FlashingTooltipsGuide;
